import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['day', 'hour', 'minute', 'second']

  connect() {
    let endTime = new Date(this.element.dataset.datetime),
        now = new Date();
    this.timeLeft = Math.floor((endTime - now) / 1000);
    this.populateTimes();
    this.stop = this.stop.bind(this);
    document.addEventListener('turbo:click', this.stop);
  }

  pad(n) {
    if (n < 0)
      return "00";
    if (n < 10)
      return "0" + n;
    return n;
  }

  populateTimes() {
    let d = this.timeLeft;
    let seconds = d % 60;
    d = Math.floor(d / 60);
    let minutes = d % 60;
    d = Math.floor(d / 60);
    let hours = d % 24;
    let days = Math.floor(d / 24);
    this.timeLeft--;

    this.dayTarget.innerHTML = this.pad(days);
    this.hourTarget.innerHTML = this.pad(hours);
    this.minuteTarget.innerHTML = this.pad(minutes);
    this.secondTarget.innerHTML = this.pad(seconds);

    if (this.timeLeft >= 0)
      this.timer = setTimeout(() => this.populateTimes(), 1000);
  }

  stop() {
    clearTimeout(this.timer);
    document.removeEventListener('turbo:click', this.stop);
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item'];

  change(e) {
    e.stopPropagation();
    let tabId = e.currentTarget.dataset.tabId,
        tabGroup = e.currentTarget.dataset.tabGroup;
    for (let i = 0; i < this.itemTargets.length; i++) {
      let element = this.itemTargets[i];
      if (element.dataset.tabGroup != tabGroup) continue;
      if (element.dataset.tabId == tabId)
        element.classList.add('current');
      else
        element.classList.remove('current');
    }
  }
}
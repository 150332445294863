import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submit', 'next', 'loading'];

  connect() {
    this.stripe = Stripe(this.element.dataset.paymentKey);
    this.elements = this.stripe.elements({
      appearance: { theme: 'stripe' },
      clientSecret: this.element.dataset.transactionCs
    });
    this.elements.create('payment').mount('#payment-element');
  }

  sendPayment(e) {
    e.preventDefault();
    this.allowSubmission(false);
    this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: { return_url: `${this.element.dataset.paymentReturnUrl}/register/results/${this.element.dataset.registrationId}` }
    }).then(result => {
      if (result.error.type === "validation_error") {
        alert(`${result.error.message} Please make sure your information is correct and try again.`);
      } else if (result.error.type === "card_error") {
        alert(`${result.error.message} Please check your information, use another payment method, or contact your financial institution.`);
      } else if (result.error.code === "processing_error") {
        alert(result.error.message);
      } else {
        alert('An unexpected error occurred. Please contact the conference organizers if the error persists.');
      }
      this.allowSubmission(true);
    });
  }

  allowSubmission(allow) {
    if (allow) {
      this.submitTarget.disabled = false;
      this.nextTarget.style.display = 'block';
      this.loadingTarget.style.display = 'none';
    } else {
      this.submitTarget.disabled = true;
      this.nextTarget.style.display = 'none';
      this.loadingTarget.style.display = 'block';
    }
  }
}